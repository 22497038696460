.common {
  &__text {
    font-size: 14px;
    font-weight: 500;
    color: #727b8f;

    &.center {
      text-align: center;
    }
  }

  &__flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.none {
      justify-content: initial;
    }

    &.center {
      justify-content: center;
    }
  }

  &__button {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: $blue;
    border-radius: 30px;
    background-color: white;
    padding: 8px 25px;
    cursor: pointer;
    transition: all .3s;

    &:active {
      transform: scale(0.9);
    }

    &:hover {
      color: white;
      background-color: $blue-light;
    }

    &--blue {
      color: white;
      background-color: $blue;
    }
  }

  &__button-gradient {
    font-size: 18px;
    font-weight: 500;
    color: white;
    border-radius: 30px;
    display: inline-block;
    padding: 16px 10px;
    width: 245px;
    text-align: center;
    box-shadow: 12px 15px 34px 0 rgba(130, 95, 244, 0.33), -12px 16px 34px 0 rgba(44, 166, 254, 0.14);
    background: linear-gradient(347deg, #c12ced, #1db1ff) 0 0;
    background-size: auto 150%;
    cursor: pointer;
    transition: all .3s;

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    &:hover {
      background-position: 0 100%;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &__arrow-drop-down {
    width: 10px;
    height: 5px;
    background-image: url($image-path + 'icons/arrow-drop-down.svg');
    cursor: pointer;
    transition: all .3s;

    &.grey {
      padding: 10px;
      background: url($image-path + 'icons/arrow-drop-down-grey.svg') no-repeat center center;
    }

    &.up {
      transform: rotate(-180deg);
    }
  }

  &__error {
    font-size: 14px;
    font-weight: 500;
    color: $red;
    opacity: 0;
    transform: translateY(15px);
    animation: fade-in 0.3s forwards;
    transition: all .3s;

    &.under-input {
      margin-top: 15px;
      padding: 0 25px;
    }
  }

  &__loading {
    width: 30px;
    height: 30px;
    background-image: url($image-path + 'icons/loading.gif');
    background-size: contain;
  }

  &__fade-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fade-in 0.3s forwards;
  }

  &__icon {
    &-chart {
      width: 20px;
      height: 12px;
      background-image: url($image-path + 'icons/chart.svg');
    }
  }
}
