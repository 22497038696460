.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  z-index: 999;
  overflow: auto;
  transition: all .3s;

  &--active {
    visibility: visible;
    opacity: 1;
  }
}

.modal {
  width: 90%;
  max-width: 560px;
  max-height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
  pointer-events: none;

  &__header {
    font-size: 18px;
    font-weight: 500;
    color: white;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.34);
    background: $blue;
    padding: 23px 30px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
  }

  &__close-btn {
    font-size: 35px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    cursor: pointer;
  }

  &__content {
    margin: 10px 0 50px;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scale(0.7);
    border-radius: 8px;
    box-shadow: 0 12px 64px 0 rgba(19, 23, 32, 0.81);
    transition: all .3s;
  }

  &--active {
    pointer-events: visible;

    .modal__content {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
  }
}

.scatter-modal {
  padding: 40px 15px 80px;

  &__connecting {
    text-align: center;
    font-weight: 600;
    color: #4a5675;
  }

  &__loading {
    width: 35px;
    height: 35px;
    margin: 20px auto;
  }

  &__logo {
    width: 37px;
    height: 67px;
    background-image: url($image-path + 'logos/scatter.svg');
    margin: 0 auto;
  }
}

.error-modal {
  padding: 30px 25px 40px;

  &__message {
    font-weight: 600;
    color: #4a5675;
    text-align: center;
    line-height: 1.5;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url($image-path + 'icons/error.svg');
      position: relative;
      top: 3px;
      margin-right: 7px;
    }
  }

  &__container {
    font-weight: 500;
    font-size: 14px;
  }

  &__content {
    margin: 35px 0 40px;
  }

  &__button {
    padding: 8px 37px;
  }
}
