.body {
  padding: 60px 0 100px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 550px;
    background: url($image-path + 'backgrounds/home.svg') bottom;
    background-size: cover;
    z-index: -1;
  }

  &__content {
    padding: 0 15px;
  }

  &__title {
    font-size: 36px;
    text-align: center;
    color: white;
    margin-bottom: 15px;
  }

  &__sub-title {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-bottom: 40px;
    letter-spacing: 1px;
    word-spacing: 1px;
  }

  @media (max-width: 750px) {
    padding-top: 35px;
    padding-bottom: 60px;

    &::before {
      height: 265px;
      background: url($image-path + 'backgrounds/home-mobile.svg') 0 310px;
      background-size: cover;
    }

    &__title,
    &__sub-title {
      line-height: 1.4;
    }

    &__title {
      font-size: 24px;
    }

    &__sub-title {
      font-size: 18px;
    }
  }

  @media (max-width: 450px) {
    &::before {
      background: url($image-path + 'backgrounds/home-mobile.svg') 0;
    }
  }

  @media (max-width: 350px) {
    &__title {
      font-size: 21px;
    }

    &__sub-title {
      font-size: 16px;
    }
  }
}
