/* ============ Settings ============ */
$container-width: 1090px;
$image-path: '../images/';

/* ============ Fonts ============ */
$font-montserrat: 'Montserrat', sans-serif;;

/* ============ Colors ============ */
$blue: #2276e5;
$blue-light: #729cff;
$red: #f9566d;
$red-light: #fff3f3;
$grey: #ccc;

/* ============ Breakpoints ============ */
$ex-large-breakpoint: 1000px;
$large-breakpoint: 950px;
$medium-breakpoint: 750px;
$min-medium-breakpoint: 650px;
$ex-small-breakpoint: 500px;
$small-breakpoint: 450px;
$smaller-breakpoint: 350px;
$smallest-breakpoint: 320px;
